<template>
  <div>
    <div class="row">
      <div class="col-12 fixed-top border-top landing-page-custom-border"></div>
      <div class="col-12 d-flex">
        <div class="ml-auto lang-switch-wrapper">
          <Nav />
        </div>
      </div>
    </div>
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-12 text-center">
        <div v-if="loading" class="loading-container">
          <Loader :loader-text="$t(statusToDataMap[TRANSACTION_STATUS.LOADING].title)" :loader-fullpage="false"
            :loader-message="$t(statusToDataMap[TRANSACTION_STATUS.LOADING].message)" class="error-page-loader" />
        </div>
        <div v-else>
          <div v-if="logoUrl">
            <LogoComponent :logo-url="logoUrl" />
          </div>
          <div>
            <h1 class="status-title">{{ $t(statusToDataMap[paymentStatus].title) }}</h1>
            <span class="status-message">{{ $t(statusToDataMap[paymentStatus].message) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from './Nav';
import LogoComponent from './LogoComponent.vue';
import { TRANSACTION_STATUS, TRANSLATION_KEY_MAP } from '../util/transaction-status';
import { mapActions } from 'vuex';
import { Loader } from '@cendyn/cendyn-frontend-kit';

export default {
  name: 'ErrorPage',
  components: {
    Nav,
    LogoComponent,
    Loader
  },
  props: {
    paymentStatus: {
      type: String,
      default: TRANSACTION_STATUS.INVALID_TRANSACTION
    }
  },
  data() {
    return {
      loading: true,
      correlationId: null,
      statusToDataMap: {
        [TRANSACTION_STATUS.LOADING]: {
          'title': TRANSLATION_KEY_MAP.LOADING_TITLE,
          'message': TRANSLATION_KEY_MAP.LOADING_MESSAGE
        },
        [TRANSACTION_STATUS.INVALID_TRANSACTION]: {
          'title': TRANSLATION_KEY_MAP.INVALID_TRANSACTION_TITLE,
          'message': TRANSLATION_KEY_MAP.INVALID_TRANSACTION_MESSAGE
        }
      },
      logoUrl: '',
      TRANSACTION_STATUS
    };
  },
  beforeMount() {
    this.setupErrorPage();
  },
  methods: {
    ...mapActions('payment', ['getWildcardDomainTemplate']),

    async setupErrorPage() {
      /**
       * Sets the loading state to true, indicating that the
       * error page is still loading.
       */
      this.loading = true;

      const url = window.location.href;

      // Parse the URL to get the hostname
      const parsedUrl = new URL(url);
      const hostname = parsedUrl.hostname;

      // Split the hostname by '.'
      const subdomains = hostname.split('.');

      // Use the desired subdomain as the wildcard domain
      const wildcardDomain = subdomains[0] || '';

      try {
        /**
         * Checks if the wildcard domain is valid and not related to payments.
         *
         * This function checks if the length of the wildcard domain is at
         * least 4 characters and if it does not include the substring
         * 'payments'.
         *
         * @return {boolean} Returns true if the wildcard domain is valid
         * and not related to payments, otherwise returns false.
         */
        if (
          /**
           * Checks if the length of the subdomains array is at least 4.
           *
           * @return {boolean} Returns true if the length of the subdomains
           * array is at least 4, otherwise returns false.
           */
          subdomains.length >= 4 &&

          // Check if the wildcard domain does not include the substring 'payments'
          !wildcardDomain.includes('payments')
        ) {
          /**
           * Call the 'getWildcardDomainTemplate' action to retrieve the logo URL
           * for the given wildcard domain.
           *
           * @param {string} wildcardDomain - The wildcard domain.
           * @returns {Promise} - A promise that resolves to the response from
           * the API containing the logo URL.
           */
          let getWildcardDomainTemplateResponse = await this.getWildcardDomainTemplate(wildcardDomain);

          /**
           * Assign the logo URL retrieved from the API to the 'logoUrl'
           * data property.
           *
           * @type {string}
           */
          this.logoUrl = getWildcardDomainTemplateResponse.logo || '';

          /**
           * Hide the loading spinner after the logo URL has been retrieved.
           *
           * This function does not take any parameters.
           *
           * @returns {void}
           */
          this.loading = false;

        }
      } catch (error) {
        /**
         * Log and handle any errors that occur during the setup of the error page.
         *
         * This function does not take any parameters.
         *
         * @returns {void}
         */
        this.loading = false; // Hide the loading spinner

        /**
         * Log the error that occurred during setup of the error page.
         *
         * @type {Error}
         */
        console.log(error);  // Log the error to the console
      }

      /**
       * If the API call to retrieve the wildcard domain template has not been made,
       * set the loading property to false.
       *
       * This function does not take any parameters and does not return anything.
       */
      this.loading = false; // Hide the loading spinner

    }
  }
};
</script>
<style scoped lang="scss">
.landing-page-custom-border {
  border-top: 18px solid #6EF3DC !important;
}

.lang-switch-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.status-title {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600;
  font-size: 40px;
  line-height: 125%;
  color: #12263F;
  margin-top: 50px;
}

.status-message {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 152%;
  color: #6E84A3;
  margin-top: 24px;
}

.loading-state__text {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.actions-container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.loading-container {
  position: relative;
  height: 200px;
}
</style>
<style lang="scss">
.error-page-loader {
  .spinner-border {
    border-top-color: #47D3BA;
    border-left-color: #47D3BA;
    border-bottom-color: #47D3BA;
  }
}
</style>
