/* eslint-disable no-empty-pattern */
// src/store.js
import {TRANSACTION_STATUS } from '@/util/transaction-status';
export const paymentStore = {
  namespaced: true,
  state: () => ({
    paymentStatus: TRANSACTION_STATUS.LOADING
  }),
  mutations: {
    SET_PAYMENT_STATUS: (state, status) => {
      // console.log(`----------- Setting payment status ::: ${status}  -----------`);
      state.paymentStatus = status;
    }
  },
  actions: {
    async getStatus({ commit }, correlationId) {
      // console.log('----------- Getting payment status -----------');
      return new Promise((resolve, reject) => {
        fetch(`/api/payments/${correlationId}/status`).then(async response => {
          if (response.status == 400) {
            reject(response.status);
          }
          const responseBody = await response.json();
          commit('SET_PAYMENT_STATUS', responseBody);
          resolve(responseBody);
        }).catch(error => {
          commit('SET_PAYMENT_STATUS', TRANSACTION_STATUS.ERROR);
          console.info(error);
          reject(error.status);
        });
      });
    },
    async startPayment({ commit }, correlationId) {
      // console.log('----------- Starting the payment -----------');
      commit('SET_PAYMENT_STATUS', TRANSACTION_STATUS.LOADING);
      return new Promise((resolve, reject) => {
        return fetch(`/api/payments/${correlationId}/start`, {method: 'POST'}).then(response => {
          if (response.status == 202) {
            commit('SET_PAYMENT_STATUS', TRANSACTION_STATUS.STARTED);
          }
          resolve(response);
        }).catch(error => {
          commit('SET_PAYMENT_STATUS', TRANSACTION_STATUS.ERROR);
          reject(error.status);
        });
      });
    },
    async getRedirect({ commit }, correlationId) {
      // console.log('----------- Redirecting to payment portal -----------');
      commit('SET_PAYMENT_STATUS', TRANSACTION_STATUS.LOADING);
      return new Promise((resolve, reject) => {
        return fetch(`/api/payments/${correlationId}/redirect`, {method: 'GET'}).then(async response => {
          if (response.status == 200) {
            const responseBody = await response.json();
            resolve(responseBody);
            return;
          }
          reject(response.status);
          return;
        }).catch(error => {
          commit('SET_PAYMENT_STATUS', TRANSACTION_STATUS.ERROR);
          reject(error.status);
        });
      });
    },
    async getTemplate({}, correlationId) {
      return new Promise((resolve, reject) => {
        return fetch(`/api/payments/${correlationId}/template`, {method: 'GET'}).then(async response => {
          if (response.status == 200) {
            const responseBody = await response.json();
            resolve(responseBody);
            return;
          }
          reject(response.status);
          return;
        }).catch(error => {
          reject(error.status);
        });
      });
    },
    async getWildcardDomainTemplate({}, wildcardDomain) {
      return new Promise((resolve, reject) => {
        return fetch(`/api/domains/${wildcardDomain}/template`, {method: 'GET'}).then(async response => {
          if (response.status == 200) {
            const responseBody = await response.json();
            resolve(responseBody);
            return;
          }
          reject(response.status);
          return;
        }).catch(error => {
          reject(error.status);
        });
      });
    }
  },
  getters: {
    getPaymentStatus: state => state.paymentStatus
  }
};
