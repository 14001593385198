<template>
	<div id="admin-integration-container" class="containerC">
		<div class="col-8 offset-2">
			<div class="row">
				<page-header-main
					id="add-integration"
					class="col-12"
					:title="isEditing ? 'Edit Integration' : 'Add Integration'"
					:breadcrumbs="[{name: 'PAYMENT PORTAL'},{name : 'INTEGRATIONS', link : '/integrations'},{name: isEditing ? 'Edit Integration' : 'Add Integration'}]"
				/>
				<div class="col-12">
					<CAlert v-if="showMessage"
						class="col-12"
						:color="messageType"
						:dismissible=false
						icon-name="alert-circle">
							{{ message }}
					</CAlert>
				</div>
				<div class="col-12">
					<p class="input-heading mb-1">Select Organization</p>
					<p class="text-secondary heading-info">Please note that the available organization types vary by property.</p>
				</div>
				<div class="col-12">
					<div class="form-inputs">
						<div class="row">
							<div class="col-12">
								<Loader v-if="loadings.length>0" :loader-fullpage="false" loader-text="Loading..." class="col-12" />
								<div class="row">
									<!-- Product Selection Dropdown -->
									<div class="col-12">
										<CDropDown
											id="product"
											:input-id="'productInputId'"
											label="Product"
											placeholder="Product"
											:required="true"
											:data-source="products"
											:fields='productFields'
											:show-clear-button="false"
											:enabled="false"
											:selected-index="selectedProductIndex"
											@onChange="handleProductChange"
										/>
									</div>
									<!-- Environment Selection Dropdown -->
									<!-- SHOW ONLY FOR eInsight PRODUCT -->
									<div v-if="isEnvironmentDropdownVisible" class="col-md-6 col-sm-12">
										<CDropDown
											id="environment"
											:input-id="'environmentInputId'"
											label="Environment"
											placeholder="Environment"
											:required="true"
											:data-source="environmentOptions"
											:fields='environmentFields'
											:show-clear-button="false"
											:enabled="!isQAEnv && !isEditing"
											:selected-index="selectedEnvironmentIndex"
											@onChange="value => handleConfigurationValueChange('environment', value.value)"
										/>
									</div>
									<!-- Property Name Input -->
									<div class="col-md-6 col-sm-12">
										<div>
										<CInput
											id="propertyName"
											label="Property Name"
											:value="configData.propertyName"
											:required="true"
											:class="{ 'is-invalid': validationMessages['propertyName'] }"
											:disabled="isEditing"
											@update="value => handleConfigurationValueChange('propertyName', value)"
										/>
										<p class="validation-message">{{ validationMessages['propertyName'] }}</p>
										</div>
									</div>
									<!-- Hotel Code Input -->
									<div class="col-md-6 col-sm-12">
										<div>
											<CInput
												id="hotelCode"
												label="Hotel Code"
												:value="configData.hotelId"
												:disabled="isEditing"
												:required="true"
												:class="{ 'is-invalid': validationMessages['hotelId'] }"
												@update="value => handleConfigurationValueChange('hotelId', value)"
											/>
											<p class="validation-message">{{ validationMessages['hotelId'] }}</p>
										</div>
									</div>
									<!-- Second Level Domain Input -->
									<div class="col-md-6 col-sm-12">
										<div>
											<CInput
												id="secondLevelDomain"
												:value="configData.secondLevelDomain"
												:disabled="isEditing"
												label="Second Level Domain"
												:required="true"
												:class="{ 'is-invalid': validationMessages['secondLevelDomain'] }"
												@update="value => handleConfigurationValueChange('secondLevelDomain', value)"
											/>
											<p class="validation-message">{{ validationMessages['secondLevelDomain'] }}</p>
										</div>
									</div>
									<div
										v-for="parameter in productParameters"
										:key="`product-param-${parameter.productSettingId}`"
										class="col-sm-12"
										:class="`col-md-${parameter.columnSize}`"
									>
										<template v-if="parameter.inputType === 'text'">
											<div>
												<CInput
												:id="`product-param-${parameter.productSettingId}`"
												:label="parameter.fieldName"
												:value="configData.productValues[parameter.productSettingId]"
												:required="true"
												:class="{ 'is-invalid': validationMessages[productParam + parameter.productSettingId] }"
												@update="value=>handleProductValueChange(parameter.productSettingId, value)"
												/>
												<p class="validation-message">{{ validationMessages[productParam + parameter.productSettingId] }}</p>
											</div>
										</template>
										<template v-else-if="parameter.inputType === 'select'">
											<CDropDown
												:id="`product-param-${parameter.fieldName}`"
												:input-id="`product-param-input-${parameter.fieldName}`"
												:label="parameter.fieldName"
												:placeholder="parameter.fieldName"
												:required="!!parameter.required"
												:selected-index="selectedProductValueIndices[parameter.productSettingId]??-1"
												:data-source="parameter.options"
												:fields="{ 'text': 'text', 'value': 'value' }"
												:show-clear-button="false"
												@onInput="handleProductValueChange"
											/>
										</template>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12"><hr /></div>
							<!-- Upload Logo Section Title -->
							<div class="col-md-7 col-sm-12">
								<p class="input-heading m-0 pt-4">Upload Logo <label class="c-label"><span class="c-label-required">*</span></label></p>
								<p class="text-secondary heading-info">Add a gif, png, or jpg no larger than 1 MB. We recommend minimum height of 200px for the best display.</p>
								<p class="validation-message">{{ validationMessages['logoFile'] }}</p>
							</div>
							<!-- Upload Logo Input -->
							<div id='dropArea' class="col-md-5 col-sm-12 my-3 d-flex align-items-center">
								<FileUpload
									id='configuration-logo'
									ref="uploadObj"
									css-class="specific"
									:class="{ 'is-invalid': validationMessages['logoFile'] }"
									name="UploadFiles"
									:drop-area="dropTarget"
									:max-file-size=1000000
									:files="files"
									allowed-extensions=".jpg,.jpeg,.png,.gif"
									:auto-upload="false"
									@selected="onFileSelected"
									/>
							</div>
							<div class="col-12"><hr /></div>
						</div>
						<div class="row">
							<div class="col-12">
								<Loader v-if="loadings.length>0" :loader-fullpage="false" loader-text="Loading..." class="col-12" />
								<div class="row">
									<!-- Service Processor Section Title -->
									<div class="col-12">
										<p class="input-heading pt-4">Service Provider</p>
									</div>
									<!-- Service Processor Info -->
									<div class="col-12">
										<CDropDown
											id="service-processor-dropdown"
											input-id="service-processor-input-id"
											label="Service Provider"
											placeholder="Service Provider"
											:required="true"
											:data-source="serviceProcessors"
											:fields='serviceProcessorFields'
											:show-clear-button="false"
											:selected-index="selectedServiceProcessorIndex"
											:value-template="serviceProcessorValueTemplate"
											:item-template="serviceProcessorValueTemplate"
											@onChange="handleServiceProcessorChange"
										/>
									</div>
									<div
										v-for="parameter in serviceProcessorParameters"
										:key="parameter.paymentProcessorSettingId"
										class="col-sm-12"
										:class="`col-md-${parameter.columnSize}`"
									>
										<template v-if="parameter.inputType === 'text'">
											<div>
												<CInput :id="`service-provider-param-${parameter.paymentProcessorSettingId}`"
														:label="parameter.fieldName"
														:value="configData.processorValues[parameter.paymentProcessorSettingId]"
														:required="true"
														:class="{ 'is-invalid': validationMessages[paymentProcessorParam + parameter.paymentProcessorSettingId] }"
														@update="value=>handleProcessorValueChange(parameter.paymentProcessorSettingId, value)" />
												<p class="validation-message">{{ validationMessages[paymentProcessorParam + parameter.paymentProcessorSettingId] }}</p>
											</div>
										</template>
										<template v-else-if="parameter.inputType === 'select'">
											<CDropDown
														:id="`processor-param-${parameter.fieldName}`"
														:input-id="`processor-param-input-${parameter.fieldName}`"
														:label="parameter.fieldName"
														:placeholder="parameter.fieldName"
														:selected-index="selectedProcessorParamIndices[parameter.paymentProcessorSettingId]??-1"
														:required="true"
														:data-source="parameter.options"
														:fields="{ 'text': 'text', 'value': 'value' }"
														:show-clear-button="false"
														@onChange="value => handleProcessorValueChange(parameter.paymentProcessorSettingId, value.value)"
														/>
										</template>
									</div>
								</div>
							</div>
							<template v-if="isEditing">
								<div class="col-12">
									<hr />
								</div>
								<div class="col-12 d-flex align-items-center">
									<div class="col-11 p-0">
										<CLabel label="Status" class="pt-4 m-0" />
										<span class="text-secondary heading-info">Deactivating this will stop new transactions, but will not affect in process transactions.</span>
									</div>
									<div class="col-1 p-0 justify-content-end d-flex align-itemscenter">
										<CToggleSwitch id="activeYN" :disabled="savingConfiguration || (loadings.length > 0)"
											:checked="configData.activeYN" class="mb-0"
											@update="value => handleConfigurationValueChange('activeYN', value)" />
									</div>
								</div>
							</template>
						</div>
						<div class="col-12 mt-5">
							<div class="row">
								<Button id="save" color="success" block="block" text="Save Integration"
									:disabled="savingConfiguration || (loadings.length>0)"
									@clicked="handleSaveConfiguration">
									<div class="d-flex justify-content-center align-items-start">
										<Spinner v-if="savingConfiguration" size="sm" shape="border" color="gray" text="" />
										&nbsp;<p>{{ savingConfiguration ? 'Saving...' : 'Save Integration' }}</p>
									</div>
								</Button>
								<Button id="cancel" css-class='e-link' color="default" block="block" text="Cancel"
									:disabled="savingConfiguration" @clicked="redirectToListingPage"></Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Button, PageHeaderMain, CDropDown, CInput, FileUpload } from '@cendyn/cendyn-frontend-kit';
import { mapActions, mapGetters } from 'vuex';
import { CONFIG_ENVIRONMENTS } from '@/util/AppConstants';
import { ServiceProviderTemplate } from '@/components/templates';

export default {
  components: {
    Button,
    PageHeaderMain,
    CDropDown,
    CInput,
    FileUpload
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false
    },
    editingConfiguration:{
      type: Object,
      default: null
    },
    editingConfigurationId:{
      type: [String, Number],
      default: null
    }
  },
  data: function () {
    return {
      CONSTANTS: {
				CONFIG_ENVIRONMENTS
			},
			validationMessages: {},
			productParam: 'product-param-',
			paymentProcessorParam: 'payment-processor-param-',
			dropTarget: '#dropArea',
      productFields: { text: 'name', value: 'code' },
      environmentFields: { text: 'name', value: 'value' },
      serviceProcessorFields: { text: 'name', value: 'paymentProcessorId' },
      serviceProcessorValueTemplate: function () {
        return {
          template: {
            extends: ServiceProviderTemplate
          }
        };
      },
      configData: {
        productCode: null,
        environment: null,
        propertyName: null,
        hotelId: null,
        secondLevelDomain: null,
        productValues: {},
        processor: null,
        processorValues: {},
        activeYN: true
      },
	  loadings:[],
	  configurationId: null,
	  showMessage: false,
	  messageType: 'info',
	  message: '',
	  savingConfiguration: false,
	  files: [],
	  selectedProductIndex: -1,
	  selectedEnvironmentIndex: -1,
	  selectedServiceProcessorIndex: -1,
	  isEditingConfigDataPopulated: false,
	  selectedProcessorParamIndices: {},
			selectedProductValueIndices: {},
			isEnvironmentDropdownVisible: true
    };
  },
  computed: {
    ...mapGetters('configuration', ['products', 'serviceProcessors', 'productParameters', 'serviceProcessorParameters']),
    isQAEnv() {
			const environment = this.$store.state.configuration.configuration.environment;
      if (environment === 'DEV' || environment === 'QA') {
				return true;
			} else {
				return false;
			}
    },
    environmentOptions() {
      return this.isQAEnv ? CONFIG_ENVIRONMENTS.QA : CONFIG_ENVIRONMENTS.PROD;
    }
  },
  beforeMount() {
    this.setupComponent();
  },
  methods: {
    ...mapActions('configuration', [
      'fetchProducts',
      'fetchServiceProcessors',
	  'fetchProductParameters',
	  'fetchServiceProcessorParameters',
	  'saveConfiguration',
	  'updateConfiguration',
	  'saveConfigurationLogo'
    ]),
    async setupComponent() {
      this.loadings.push(true);
      await this.fetchProducts();
	  await this.fetchServiceProcessors();
	  if (this.isEditing){
        this.configData.hotelId = `${this.editingConfiguration.hotelId}`;// || this.editingConfiguration.hotelId;
        this.configData.propertyName = this.editingConfiguration.name;
        this.configData.activeYN = this.editingConfiguration.activeYN;
        this.configData.processor = this.editingConfiguration.paymentProcessorId;
        this.configData.secondLevelDomain = this.editingConfiguration.secondLevelDomain;
        this.selectedProductIndex = this.products.findIndex( product => product.code === this.editingConfiguration.productCode);
        this.selectedEnvironmentIndex = this.environmentOptions.findIndex(env => env.value === this.editingConfiguration.discriminator);
        this.selectedServiceProcessorIndex = this.serviceProcessors.findIndex( processor => processor.paymentProcessorId === this.editingConfiguration.paymentProcessorId);
        //Rendering the previously uploaded logo
        if (this.editingConfiguration.configLogo){
          const fileUploader = document.getElementById('configuration-logo');
          const imgtag = fileUploader.getElementsByClassName('upload-preview-img')[0];
          imgtag.src = `${window.location.origin}${this.editingConfiguration.configLogo}`;
          const parent = document.querySelector('#single-upload-container');
          parent.classList.add('image-uploaded');
        }
	  } else {
        this.selectedProductIndex = 0;
        this.selectedServiceProcessorIndex = 0;
        this.selectedEnvironmentIndex = 0;
      }
	  this.loadings.pop();
    },
    async handleProductChange({ code }) {
      this.configData.productCode = code;
      this.loadings.push(true);
      const productParams = await this.fetchProductParameters(code);
	  let productValues = {};
	  if (this.isEditing){
        let editingConfigProdValues = {};
        this.editingConfiguration.productValues.forEach(prodValue => { editingConfigProdValues[prodValue.productSettingId] = prodValue.configurationValue; });
        productParams.forEach(param => {
          let value = editingConfigProdValues[param.productSettingId];
           if (param.inputType === 'select') {
            this.selectedProductValueIndices[param.productSettingId] = param.options.findIndex(option => option.value === value);
		   }
          productValues[param.productSettingId] = value;
        });
	  } else {
        productParams.forEach(param => { productValues[param.productSettingId] = null; });
        this.selectedProductValueIndices = {};
	  }
	  this.configData.productValues = productValues;
	  this.loadings.pop();
    },
    async handleServiceProcessorChange({ paymentProcessorId }) {
      this.configData.processor = paymentProcessorId;
      this.loadings.push(true);
      const serviceProcessorParams = await this.fetchServiceProcessorParameters(paymentProcessorId);
	  let processorValues = {};
	  if (this.isEditing){
        let editingConfigProcessorValues = {};
	  	this.editingConfiguration.paymentProcessorValues.forEach(procValue => { editingConfigProcessorValues[procValue.paymentProcessorSettingId] = procValue.configurationValue; });
        serviceProcessorParams.forEach(param => {
          let value = editingConfigProcessorValues[param.paymentProcessorSettingId];
          if (param.inputType === 'select') {
            this.selectedProcessorParamIndices[param.paymentProcessorSettingId] = param.options.findIndex(option => option.value === value);
          }
          processorValues[param.paymentProcessorSettingId] = value;
        });
	  } else {
        serviceProcessorParams.forEach(param => { processorValues[param.paymentProcessorSettingId] = null; });
        this.selectedProcessorParamIndices = {1:0}; // Add default selected-index for paymentProcessorSettingId = 1 
	  }
	  this.configData.processorValues = processorValues;
	  this.loadings.pop();
    },
		handleConfigurationValueChange(field, value) {
			this.showValidationMessage(field, value); // Display a validation message if necessary
      this.configData[field] = value;
    },
    handleProductValueChange(field, data) {
			this.showValidationMessage(this.productParam + field, data); // Display a validation message if necessary
      this.configData.productValues[field] = data;
    },
    handleProcessorValueChange(field, data) {
			this.showValidationMessage(this.paymentProcessorParam + field, data); // Display a validation message if necessary
      this.configData.processorValues[field] = data;
    },
    onFileSelected(file) {
	  this.showValidationMessage('logoFile', file.filesData[0].rawFile.name);
	  this.files.length = 0;
      const allowedTypes = ['.gif', '.png', '.jpg'];
      if (!allowedTypes.some(type => file.filesData[0].rawFile.name.toLowerCase().endsWith(type))) {
        this.showMessage = true;
        this.messageType = 'danger';
        this.message = 'Add logo in a gif, png, or jpg format only.';
      } else if (file.filesData[0].rawFile.size > 1000000) {
        this.showMessage = true;
        this.messageType = 'danger';
        this.message = 'Logo file size should be less than 1 MB.';
      } else {
        this.showMessage = false;
        this.message = '';
        this.files.push(file.filesData[0].rawFile);
      }
    },
    isConfigurationDataValid() {
      // Check if any of the main configuration data fields are null
      for (let key in this.configData) {
        if (this.configData[key] === null) {
          this.showValidationMessage(key, ''); // Display a validation message if necessary
        }
      }
			
      // Check if any of the product values are null
      for (let key in this.configData.productValues) {
        if (this.configData.productValues[key] === null) {
          this.showValidationMessage(this.productParam + key, ''); // Display a validation message if necessary
        }
      }
			
      // Check if any of the processor values are null
      for (let key in this.configData.processorValues) {
        if (this.configData.processorValues[key] === null) {
          this.showValidationMessage(this.paymentProcessorParam + key, ''); // Display a validation message if necessary
        }
      }
			
      // Check if the logo file is valid if we are not editing.
      if (!this.isEditing && this.files.length <= 0) {
        this.showValidationMessage('logoFile', ''); // Display a validation message if necessary
      }

      return (
        this.configData.productCode
			&& this.configData.environment
			&& this.configData.propertyName
			&& this.configData.hotelId
			&& this.configData.secondLevelDomain
			&& this.configData.processor
			&& Object.entries(this.configData.productValues).map(([, value]) => value).every(value => value !== null && value !== '' && value.trim() !== '')
			&& Object.entries(this.configData.processorValues).map(([, value]) => value).every(value => value !== null && value !== '' && value.trim() !== '')
			&& (this.isEditing || this.files.length>0)
      );
    },
		async handleSaveConfiguration() {
			if (!this.isConfigurationDataValid()) {
        this.showMessage = true;
        this.messageType = 'danger';
        if (this.message.length === 0){
          this.message = 'Please fill all the required fields';
        }
		 		return;
	   	}
	  const productValues = Object.entries(this.configData.productValues).map(([key, value]) => {return {'productSettingId': +key, 'configurationValue': value};});
	  const processorValues = Object.entries(this.configData.processorValues).map(([key, value]) => {return {'paymentProcessorSettingId': +key, 'configurationValue': `${value}`};});
	  let configuration = {};
	  if (this.isEditing){
		  configuration = {
			  'activeYN': this.configData.activeYN,
			  'secondLevelDomain': this.configData.secondLevelDomain,
			  'paymentProcessorValues': processorValues,
			  'productValues': productValues
		  };
	  } else {
        configuration = {
          'productCode': this.configData.productCode,
          'name': this.configData.propertyName,
          'hotelId': +this.configData.hotelId,
          'discriminator': this.configData.environment,
          'activeYN': this.configData.activeYN,
          'paymentProcessorId': +this.configData.processor,
          'secondLevelDomain': this.configData.secondLevelDomain,
          'paymentProcessorValues': processorValues,
          'productValues': productValues
        };
	  }
	  this.showMessage = false;
	  this.savingConfiguration= true;
	  let configurationId = null;
	  let configSaved = false;
	  if (this.isEditing){
        configurationId = this.editingConfigurationId;
        configSaved = await this.updateConfiguration({configurationId, configuration});
	  } else {
        configurationId = await this.saveConfiguration(configuration);
        configSaved = !!configurationId;
	  }
			if (configSaved) {
				// If the configuration saved successfully
				if (this.files.length > 0) {
					// Check logo : If there is a logo file to upload
          const fileUploaded = await this.saveConfigurationLogo({
            configurationId,
            file: this.files[0]
					});
          if (fileUploaded) {
						// If the logo upload successfully
            this.showMessage = true;
            this.message = 'Configuration saved successfully.';
						this.messageType = 'success';
						this.redirectToListingPage();
					} else {
						// if the logo upload failed
            this.showMessage = true;
            this.message = 'Logo upload failed. You will be redirected to the ‘edit’ screen to re-upload the logo.';
            this.messageType = 'danger';
            setTimeout(() => {
              this.$router.push({ name: 'EditIntegration', params: { configurationId: configurationId, isEditing: true } });
            }, 5000);
          }
				} else {
					// Check logo : If there is no logo file selected
          this.showMessage = true;
          this.message = 'Configuration saved successfully.';
					this.messageType = 'success';
					this.redirectToListingPage();
        }
			} else {
				// if the configuration save failed
        this.showMessage = true;
        this.message = 'Failed to save configuration.';
        this.messageType = 'danger';
      }
      this.savingConfiguration = false;
    },
		redirectToListingPage() {
      setTimeout(() => {
        this.$router.push('/integrations');
      }, 1000);
		},
		/**
		 * Show a validation message for a specific field
		 * @param {string} field - The name of the field to validate
		 * @param {string} data - The data to validate
		 */
		showValidationMessage(field, data) {
			// Do not validate the 'activeYN' field. Because this field does not require a validation message.
			if (field === 'activeYN') {
				return;
			}
			// Check if the data is empty
			if (data.trim() === '') {
        // If it is, set the error message to 'This field is required.'
        this.validationMessages[field] = 'This field is required.';
			} else {
				// If it is not, clear any existing error message
				this.showMessage = false;
        this.message = '';
        this.validationMessages[field] = ''; // Clear the error message
      }
    }

  },
	watch: {
		'configData.productCode': function (newValue) {
			if (newValue !== 'eInsight') {
				this.isEnvironmentDropdownVisible = false; // Set a data property to hide the dropdown
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.form-inputs{
	padding-bottom: 80px;
}
.status-text{
	font-size: 14px;
}
</style>
<style lang="scss">
		#product,
		#environment,
		#processor-param-Environment {
			span.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard.e-valid-input.e-success.is-valid {
				border-color: #D2DDEC;
			}
		}
	
		.c-label {
			.c-label-required {
				color: #B82C45;
			}
		}

		.e-grid {
			.e-gridpager.e-pager {
				.e-pagertemplate {
					.control-section {
						.c-pagination {
							.pagination.pagination-tabs {
								.active {
									.page {
										border: none;
									}
								}
							}
						}
					}
				}
			}
		}

		#admin-integration-container {
			font-family: "Cerebri Sans", sans-serif;
			font-weight: 400;

			.input-heading{
				font-size: 16px;
				color: #12263F;
				line-height: 20.32px;
			}

			.heading-info {
				font-size: 14px;
				line-height: 23.11px;
			}
		}

		.validation-message {
			color: #B82C45;
		}
		.is-invalid {
			margin-bottom: 5px;
			input {
				border-color: #B82C45;
			}
		}
		
		.is-invalid {
			.customdroparea {
				border-color: #B82C45 !important;
			}
		}

		#single-upload-container {
			#drop {
				padding: 30px;
			}
		}
</style>