const appData = [
  {
    applicationId: 0,
    name: 'Payment Portal',
    description: '',
    applicationStatus: true,
    urlLink: 'localhost',
    isLegacyApplication: false,
    showAppLaunch: false,
    icon: 'app-payment-portal',
    // graphic: 'graphic-revenue',
    modules: [
      {
        separationTitle: 'Payment Portal',
        sortOrder: 1,
        moduleId: 1,
        name: 'Integrations',
        displayName: 'Integrations',
        description: '',
        status: true,
        icon: 'grid',
        moduleUrl: '/integrations',
        displayFlag: true
      }
    ]
  }
];

export default appData;
