import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../locales/en.js';
import de from '../locales/de.js';
import sl from '../locales/sl.js';
import getBrowserLocale from '../util/i18n/get-browser-locale';
import { supportedLocalesInclude } from '../util/i18n/supported-locales';
import {
  getChoiceIndex,
  setDefaultChoiceIndexGet
} from '../util/i18n/choice-index-for-plural';

Vue.use(VueI18n);
function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || 'en';
  }
}

setDefaultChoiceIndexGet(VueI18n.prototype.getChoiceIndex);

VueI18n.prototype.getChoiceIndex = getChoiceIndex;
const startingLocale = getStartingLocale();

const i18n = new VueI18n({
  locale: startingLocale,
  fallbackLocale: 'en',
  messages: {
    en: en,
    de: de,
    sl: sl
  }
});

export default i18n;

export const getCookie = function(cookieName) {
  // Split cookies into an array
  const cookies = document.cookie.split(';');
  // Find the cookie with the specified name
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if this cookie has the specified name
    if (cookie.startsWith(cookieName + '=')) {
      // Return the cookie value
      return decodeURIComponent(cookie.substring(cookieName.length + 1));
    }
  }
  // Return empty string if cookie not found
  return '';
};

export const updateLocaleCookie = function(locale) {
  document.cookie = `selected-locale=${locale}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};
export const deleteLocaleCookie = function() {
  document.cookie = 'selected-locale=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
};