export default {
  computed: {
    $pageHeaderTitle() {
      let title = this.$route.name;
      if (title === 'Default') {
        title = this.$route.path.substr(1).replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
      }
      return title;
    },
    $pageHeaderBreadcrumbs() {
      console.log('this.$route', this.$route);
      let breadcrumbs = [];
      if (this.$route.meta && Array.isArray(this.$route.meta.breadcrumb)) {
        breadcrumbs = JSON.parse(JSON.stringify(this.$route.meta.breadcrumb));
        breadcrumbs.forEach(b => {
          let breadcrumbParamKey = b.name ? b.name.match('{(.*?)}') : null;
          if (breadcrumbParamKey) {
            b.name = b.name.replace(breadcrumbParamKey[0], this.$route.params[breadcrumbParamKey[1]]);
          }

          breadcrumbParamKey = b.link ? b.link.match('{(.*?)}') : null;
          if (breadcrumbParamKey) {
            b.link = b.link.replace(breadcrumbParamKey[0], this.$route.params[breadcrumbParamKey[1]]);
          }
        });
      }
      return breadcrumbs;
    }
  }
};
