<template>
    <div class="container-fluid">
        <component :is="this.$route.meta.layout || 'div'">
            <router-view />
        </component>
    </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
    };
  },
  mounted() {
  }
};
</script>

<style lang="scss">
@import "../node_modules/@cendyn/cendyn-frontend-kit/src/assets/scss/theme.scss";
@import "//fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";

</style>
