<template>
  <Card v-if="data">
    <template v-slot:body>
      <div class="row align-items-center no-gutters">
        <div class="col-md-2 col-lg-1 col-sm-12 d-flex justify-content-center pr-0">
          <Avatar size="avatar" initial="Payment Processor Logo" :img="paymentLogo" />
        </div>
        <div class="col-md-10 col-lg-11 col-sm-12 pl-3">
          <div class="pl-2">
            <h4 class="m-0">{{ data.label || data.name }}</h4>
            <p class="text-secondary heading-info m-0">Innovative Solutions for Payment Transactions</p>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>
  
<script>
import {Card, Avatar} from '@cendyn/cendyn-frontend-kit';
  
export default {
  name: 'ServiceProviderTemplate',
  components: {
    Card,
    Avatar
  },
  data() {
    return {
      data: {}
    };
  },
  computed:{
    paymentLogo(){
      if (!this.data?.paymentProcessorId) {
        return '';
      }
	    return require(`@/assets/images/payment_processors/${this.data.paymentProcessorId}.png`);
    }
  }
};
</script>
<style lang="scss">
  #service-processor-dropdown {
    pointer-events: none;

    .e-input-group {
      height: auto !important;
      border: none;
      background: transparent;

      .e-input-group-icon {
        display: none !important;
      }
    }

    .c-label {
      .c-label-text {
        display: none;
      }
    }
  }
</style>
  