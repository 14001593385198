// German
export default {
  PageTitle: 'Reservierungszahlung',
  //loading,Started
  loading_title: 'Überprüfung, bitte warten',
  loading_message: 'Schließen Sie diese Meldung nicht',
  //invalid-transaction
  NoTransaction_title: 'Fehler',
  NoTransaction_message: 'Ihre Reservierungsdaten zum Abschließen dieser Zahlung konnten nicht gefunden werden. Bitte kontaktieren Sie das Hotel direkt.',
  //Pending,Authorized,Finalized
  Authorized_title: 'Transaktion abgeschlossen',
  Authorized_message: 'Ihre Zahlung war erfolgreich. Überprüfen Sie Ihren Posteingang auf eine Bestätigungs via E-Mail.',
  //Failed, Expired, Cancelled
  Error_title: 'Trasaktion fehlgeschlagen',
  Error_message: 'Bei der Belastung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie das Hotel',
  //Sent
  Sent_title: 'Vielen Dank!',
  Sent_message: 'Ihr Zahlvorgang ist in Arbeit. Nach erfolgreicher Abwicklung senden wir Ihnen eine Bestätigung via E-Mail.',
  //actions-buttons
  Action_TryAgain: 'Versuchen Sie es erneut'
};