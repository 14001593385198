import Api from '@/util/Api';
export const configurationStore = {
  namespaced: true,
  state: () => ({
    configuration: null,
    products: [],
    serviceProcessors: [],
    productParameters: [],
    serviceProcessorParameters: []
  }),
  mutations: {
    set(state, data) {
      state.configuration = data;
    },
    SET_PRODUCTS(state, data) {
      state.products = data;
    },
    SET_SERVICE_PROVIDERS(state, data) {
      state.serviceProcessors = data;
    },
    SET_PRODUCT_PARAMETERS(state, data) {
      state.productParameters = data;
    },
    SET_SERVICEPROCESSOR_PARAMETERS(state, data) {
      state.serviceProcessorParameters = data;
    }
  },
  actions: {
    async loadConfiguration({ commit, getters }) {
      const existingConfig = await getters.get;
      if (existingConfig) {
        return Promise.resolve(existingConfig);
      } else {
        return new Promise((resolve, reject) => {
          fetch('/api/configuration')
            .then(async response => {
              if (response.status === 400) {
                reject(response.status);
              }
              const responseBody = await response.json();
              commit('set', responseBody);
              resolve(responseBody); // Return fetched configuration
            })
            .catch(error => {
              reject(error.status);
            });
        });
      }
    },
    async fetchProducts({ commit }) {
      const products = await Api.get('/api/admin/products');
      commit('SET_PRODUCTS', products);
      return products;
    },
    async fetchServiceProcessors({ commit }) {
      const serviceProcessors = await Api.get('/api/admin/processors');
      commit('SET_SERVICE_PROVIDERS', serviceProcessors);
      return serviceProcessors;
    },
    async fetchProductParameters({commit}, productCode){
      let productParameters = await Api.get(`/api/admin/products/${productCode}/configuration`);
      commit('SET_PRODUCT_PARAMETERS', productParameters);
      return productParameters;
    },
    async fetchServiceProcessorParameters({ commit }, processorId){
      let serviceProcessorParameters = await Api.get(`/api/admin/processors/${processorId}/configuration`);
      commit('SET_SERVICEPROCESSOR_PARAMETERS', serviceProcessorParameters);
      return serviceProcessorParameters;
    },
    async saveConfiguration(state, data) {
      const response = await Api.post('/api/admin/configurations', data);
      return isNaN(parseInt(response))? null: response;
    },
    async updateConfiguration(state, { configurationId, configuration }) {
      const response = await Api.put(`/api/admin/configurations/${configurationId}`, configuration);
      return response.status === 200;
    },
    async saveConfigurationLogo(state, {configurationId, file }) {
      const data = new FormData();
      data.append('file', file);
      const response = await Api.put(`/api/admin/configurations/${configurationId}/assets?assertType=logo`, data, true);
      return (response.status === 200);
    },
    async fetchConfiguration(state, configurationId) {
      return await Api.get(`/api/admin/configurations/${configurationId}`);
    },
    async fetchConfigurationLogo(state, configurationId){
      try {
        // Send a GET request to the server to fetch the logo of a configuration.
        const response = await Api.get(`/api/admin/configurations/${configurationId}/assets?assertType=logo`);
        // Return the fetched logo.
        return response;
      } catch (error) {
        // If an error occurs, return an empty string.
        return '';
      }
    }
  },
  getters: {
    get: state => state.configuration,
    products: state => state.products,
    serviceProcessors: state => state.serviceProcessors,
    productParameters: state => state.productParameters,
    serviceProcessorParameters: state => state.serviceProcessorParameters
  }
};
