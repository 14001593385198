import './assets/main.css';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import oktaAuthPromise from './authentication';
import OktaVue from '@okta/okta-vue';
import i18n from './language';
import UIKitComponents from '@/util/toolkit';
require('../node_modules/jquery/dist/jquery');
require('../node_modules/bootstrap/dist/js/bootstrap.bundle.min');

import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9feXVRRWVYWUR2X0c=');


oktaAuthPromise.then((oktaAuth) => {
  Vue.prototype.$authService = oktaAuth;
  Vue.use(OktaVue, {
    oktaAuth
  });
  UIKitComponents();
  const app = new Vue({
    router, // Use the router
    store,
    i18n,
    oktaAuth,
    render: h => h(App) // Render the root component
  });
  app.$mount('#app');
});
Vue.config.errorHandler = (err, vm, info) => {
  console.error('Error caught by global error handler:', err, info);
  // Handle the error (e.g., show an error message or redirect to an error page)
  // Example: router.push('/error-page');
};

