import { render, staticRenderFns } from "./LogoComponent.vue?vue&type=template&id=2388c748&scoped=true"
import script from "./LogoComponent.vue?vue&type=script&lang=js"
export * from "./LogoComponent.vue?vue&type=script&lang=js"
import style0 from "./LogoComponent.vue?vue&type=style&index=0&id=2388c748&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!C:\\npm\\prefix\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2388c748",
  null
  
)

export default component.exports