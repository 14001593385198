export const TRANSLATION_KEY_MAP = {
  //ACTIONS
  TRY_AGAIN: 'Action_TryAgain',
  CLOSE_WINDOW: 'Action_CloseWindow',
  //STATUS
  //Loading
  LOADING_TITLE: 'loading_title',
  LOADING_MESSAGE: 'loading_message',
  //No Transaction
  INVALID_TRANSACTION_TITLE: 'NoTransaction_title',
  INVALID_TRANSACTION_MESSAGE: 'NoTransaction_message',
  //started
  STARTED_TITLE: 'loading_title',
  STARTED_MESSAGE: 'loading_message',
  //Not Started
  NOT_STARTED_TITLE: 'loading_title',
  NOT_STARTED_MESSAGE: 'loading_message',
  //Pending
  PENDING_TITLE: 'Authorized_title',
  PENDING_MESSAGE: 'Authorized_message',
  //Authorized
  AUTHORIZED_TITLE: 'Authorized_title',
  AUTHORIZED_MESSAGE: 'Authorized_message',
  //Finalized
  FINALIZED_TITLE: 'Authorized_title',
  FINALIZED_MESSAGE: 'Authorized_message',
  //Product Notified
  PRODUCT_NOTIFIED_TITLE: 'Authorized_title',
  PRODUCT_NOTIFIED_MESSAGE: 'Authorized_message',
  //Error
  FAILED_TITLE: 'Error_title',
  FAILED_MESSAGE: 'Error_message',
  //Expired
  EXPIRED_TITLE: 'Error_title',
  EXPIRED_MESSAGE: 'Error_message',
  //Cancelled
  CANCELLED_TITLE: 'Error_title',
  CANCELLED_MESSAGE: 'Error_message',
  //Sent
  SENT_TITLE: 'Authorized_title',
  SENT_MESSAGE: 'Authorized_message',
  //ERROR
  ERROR_TITLE: 'Error_title',
  ERROR_MESSAGE: 'Error_message'
};
export const TRANSACTION_STATUS = {
  STARTED: 'Started',
  NOT_STARTED: 'NotStarted',
  PENDING: 'Pending',
  AUTHORIZED: 'Authorized',
  FINALIZED: 'Finalized',
  PRODUCT_NOTIFIED: 'ProductNotified',
  FAILED: 'Failed',
  EXPIRED: 'Expired',
  CANCELLED: 'Canceled',
  LOADING: 'Loading',
  INVALID_TRANSACTION: 'InvalidTransaction',
  SENT: 'Sent',
  ERROR: 'Error'
};
export const TRANSACTION_STATUS_DATA = {
  [TRANSACTION_STATUS.LOADING]: {
    'title': TRANSLATION_KEY_MAP.LOADING_TITLE,
    'message': TRANSLATION_KEY_MAP.LOADING_MESSAGE
  },
  [TRANSACTION_STATUS.STARTED]: {
    'title': TRANSLATION_KEY_MAP.STARTED_TITLE,
    'message': TRANSLATION_KEY_MAP.STARTED_MESSAGE
  },
  [TRANSACTION_STATUS.NOT_STARTED]: {
    'title': TRANSLATION_KEY_MAP.NOT_STARTED_TITLE,
    'message': TRANSLATION_KEY_MAP.NOT_STARTED_MESSAGE
  },
  [TRANSACTION_STATUS.PENDING]: {
    'title': TRANSLATION_KEY_MAP.PENDING_TITLE,
    'message': TRANSLATION_KEY_MAP.PENDING_MESSAGE
  },
  [TRANSACTION_STATUS.AUTHORIZED]: {
    'title': TRANSLATION_KEY_MAP.AUTHORIZED_TITLE,
    'message': TRANSLATION_KEY_MAP.AUTHORIZED_MESSAGE
  },
  [TRANSACTION_STATUS.FINALIZED]: {
    'title': TRANSLATION_KEY_MAP.FINALIZED_TITLE,
    'message': TRANSLATION_KEY_MAP.FINALIZED_MESSAGE
  },
  [TRANSACTION_STATUS.PRODUCT_NOTIFIED]: {
    'title': TRANSLATION_KEY_MAP.PRODUCT_NOTIFIED_TITLE,
    'message': TRANSLATION_KEY_MAP.PRODUCT_NOTIFIED_MESSAGE
  },
  [TRANSACTION_STATUS.FAILED]: {
    'title': TRANSLATION_KEY_MAP.FAILED_TITLE,
    'message': TRANSLATION_KEY_MAP.FAILED_MESSAGE
  },
  [TRANSACTION_STATUS.EXPIRED]: {
    'title': TRANSLATION_KEY_MAP.EXPIRED_TITLE,
    'message': TRANSLATION_KEY_MAP.EXPIRED_MESSAGE
  },
  [TRANSACTION_STATUS.CANCELLED]: {
    'title': TRANSLATION_KEY_MAP.CANCELLED_TITLE,
    'message': TRANSLATION_KEY_MAP.CANCELLED_MESSAGE
  },
  [TRANSACTION_STATUS.ERROR]: {
    'title': TRANSLATION_KEY_MAP.ERROR_TITLE,
    'message': TRANSLATION_KEY_MAP.ERROR_MESSAGE
  },
  [TRANSACTION_STATUS.INVALID_TRANSACTION]: {
    'title': TRANSLATION_KEY_MAP.INVALID_TRANSACTION_TITLE,
    'message': TRANSLATION_KEY_MAP.INVALID_TRANSACTION_MESSAGE
  }
  // [TRANSACTION_STATUS.SENT]: {
  //   'title': TRANSLATION_KEY_MAP.SENT_TITLE,
  //   'message': TRANSLATION_KEY_MAP.SENT_MESSAGE
  // }
};