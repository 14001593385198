<template>
  <div :class="['auth-layout', dynamicNav]">
    <template>
      <a id="swapNav" href="#" :class="dynamicNav" @click.prevent="switchNav()">
        <base-icon
          icon-name="menu-min"
          size="16"
          viewBox="0 0 16 16"
        ></base-icon>
      </a>
      <component
        :is="dynamicNav"
        application-name="Payment Portal"
        application-icon="app-revenue"
        :show-application-icon=false
        :show-app-launcher-icon=false
        :applications="SIDEBAR_NAV_ITEMS"
        :user-applications="[0]"
      >
      </component>
      <div class="main-content">
        <TheTopnav
          :show-organization-switcher=false
          :show-return-home=false
          :org-type="organizationType"
          :org-data="[]"
          :search="true"
          :notification="false"
          :locale="false"
          :locale-icon="'settings'"
          :locale-links="[]"
          :user="true"
          :user-alt="userName"
          :user-initials="userInitials"
          :user-img="''"
          :user-links="[]"
          :user-logout="'Logout'"
          :home="false"
          @logout.prevent="logout()"
        >
          <template #searchform>
            <a :href="help.link" target="_blank" class="dropdown-item">
              {{ help.label }}
            </a>
          </template>
        </TheTopnav>
        <div class="container-fluid">
          <slot />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SIDEBAR_NAV_ITEMS from '@/data/appData';

export default {
  name: 'MainLayout',
  data() {
    return {
      SIDEBAR_NAV_ITEMS,
      dynamicNav: 'the-nav',
      organizationType: 'App',
      //TODO:: LKI ::  Update this according to payment portal data
      help: {
        label: 'Help',
        link: 'https://help.cendyn.com/'
      }
    };
  },
  computed: {
    userName() {
      const claims = this.$data.authState?.idToken?.claims;
      return claims?.name ?? '';
    },
    userInitials() {
      const name = this.userName ?? '';

      // Split the name into an array of words, map each word to its first character,
      // and join the characters into a string.
      return name.split(' ')
        .map(word => word?.charAt(0) ?? '')
        .join('');
    }
  },
  methods: {
    switchNav() {
      const display = this.dynamicNav === 'the-nav-small' ? 'the-nav' : 'the-nav-small';
      this.dynamicNav = display;
    },
    async logout() {
      await this.$auth.signOut({
        postLogoutRedirectUri: `${window.location.origin}/integrations`
      });
    },
    /**
     * Adds the 'active' class to the element with the id 'pageIntegrations' if it exists.
     *
     * @return {void} 
     */
    addActiveClass() {
      let element = document.getElementById('pageIntegrations');
      if (element) {
        element.classList.add('active');
      }
    }
  },
  /**
   * Mounts the component and adds the 'active' class to the element with the id 'pageIntegrations' if it exists.
   *
   * @return {void} 
   */
  mounted() {
    this.addActiveClass();
  },
  watch: {
    /**
     * Watches for route changes and updates the active class on the integration navigation item.
     * @param {Object} to - The route object that the application is currently on.
     * @param {Object} from - The route object that the application was previously on.
     * @return {void} This function does not return anything.
     */
    '$route'(to, from) {
      if (to !== from) {
        this.$nextTick(() => {
          this.addActiveClass();
        });
      }
    }
  }
};
</script>
<style lang="scss">
  #sidebar .navbar-brand {
    display: inline-block;
    width: 100%;
    height: 40px;
    background: url('/payment-portal-icon.svg') no-repeat center;

    svg {
      display: none;
    }
  }

  #app_launcher span {
    z-index: 2;
  }

  #app_launcher::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -40px;
    width: 200px;
    height: 24%;
    background-image: url("/payment-portal-icon.svg");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.25;
    z-index: 1;
  }

  a#current_user:hover {
    background: transparent;
  }

  .navbar-nav .nav-item {
    a {
      &:hover {
        background: transparent;
      }
    }
  }

  .avatar-title {
    background-color: #276ECD;
  }

  #sidebarSmall {
    /* Hide the 'app-revenue' icon */
    .base-icon.app-revenue {
      display: none;
    }

    /* Add new icon */
    .navbar-brand-img::after {
        content: "";
        display: inline-block;
        width: 32px;
        height: 32px;
        background: url('/payment-portal-icon.svg');
        background-size: cover;
    }

    .navbar-brand {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
</style>