import Vue from 'vue';
import VueRouter from 'vue-router';
import LandingPage from '@/components/LandingPage.vue';
import AddIntegration from '@/components/AddIntegration.vue'; // Import the component for Add Integration
import ErrorPage from '@/components/ErrorPage.vue';
import { LoginCallback } from '@okta/okta-vue';
import Integrations from '@/components/Integrations.vue';
import MainLayout from '@/components/MainLayout.vue';
import EditIntegration from '@/components/EditIntegration.vue';

const routes = [
  {
    path: '/login/callback',
    component: LoginCallback,
    meta: { requiresAuth: false } // Anonymous route
  },
  {
    path: '/integrations',
    component: Integrations,
    meta: { requiresAuth: true, layout: MainLayout }
  },
  {
    // Dynamic route with correlationId as a parameter
    path: '/:correlationId(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})',
    props:true,
    component: LandingPage, // Replace with the actual component
    meta: { requiresAuth: false } // Requires authentication
  },
  {
    path: '/add-integration', // New route for Add Integration
    name: 'AddIntegration',
    component: AddIntegration, // Replace with the actual component for Add Integration
    meta: { requiresAuth: true, layout: MainLayout } // Requires authentication
  },
  {
    path: '/edit-integration/:configurationId', // New route for Add Integration
    name: 'EditIntegration',
    props: true,
    component: EditIntegration, // Replace with the actual component for Add Integration
    meta: { requiresAuth: true, layout: MainLayout } // Requires authentication
  },
  {
    path: '/error',
    component: ErrorPage,
    meta: { requiresAuth: false } // Requires authentication
  }
  // Add more routes as needed
];

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active'
});

router.beforeEach(async (to, from, next) => {
  const oktaAuth = Vue.prototype.$authService;
  if (to.meta.requiresAuth && !oktaAuth.isAuthenticated()) {
    await oktaAuth.signInWithRedirect({ originalUri: to.fullPath });
  } else {

    next();
  }
});
Vue.use(VueRouter);
export default router;
