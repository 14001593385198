<template>
  <div>
    <div class="row">
      <div class="col-12 fixed-top border-top landing-page-custom-border"></div>
      <div class="col-12 d-flex">
        <div class="ml-auto lang-switch-wrapper">
          <Nav />
        </div>
      </div>
    </div>
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-12 text-center">
        <LogoComponent :logo-url="logoUrl" />
        <div v-if="paymentStatus === TRANSACTION_STATUS.LOADING" class="loading-container">
          <Loader :loader-text="$t(statusToDataMap[paymentStatus].title)" :loader-fullpage="false"
            :loader-message="$t(statusToDataMap[paymentStatus].message)" class="landing-page-loader" />
        </div>
        <div v-else>
          <h1 class="status-title">{{ $t(statusToDataMap[paymentStatus].title) }}</h1>
          <span class="status-message">{{ $t(statusToDataMap[paymentStatus].message) }}</span>

          <div class="actions-container">
            <div v-for="action in  statusToDataMap[paymentStatus].actions" :key="$t(action.text)">
              <Button :text="$t(action.text)" :color="action.color" @clicked="action.action" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TRANSACTION_STATUS, TRANSACTION_STATUS_DATA, TRANSLATION_KEY_MAP } from '../util/transaction-status';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Button, Loader } from '@cendyn/cendyn-frontend-kit';
import Nav from './Nav';
import LogoComponent from './LogoComponent.vue';
export default {
  components: {
    Nav,
    Loader,
    Button,
    LogoComponent
  },
  props:{
    correlationId:{
      type:String,
      default: null
    }
  },
  data() {
    return {
      logoUrl: '',
      TRANSACTION_STATUS,
      statusActions: {
        [TRANSACTION_STATUS.FAILED]: [
          {
            'text': TRANSLATION_KEY_MAP.TRY_AGAIN,
            'color': 'transaction-primary',
            'action': this.handleStartPayment
          }
        ],
        [TRANSACTION_STATUS.EXPIRED]: [
          {
            'text': TRANSLATION_KEY_MAP.TRY_AGAIN,
            'color': 'transaction-primary',
            'action': this.handleStartPayment
          }
        ],
        [TRANSACTION_STATUS.CANCELLED]: [
          {
            'text': TRANSLATION_KEY_MAP.TRY_AGAIN,
            'color': 'transaction-primary',
            'action': this.handleStartPayment
          }
        ],
        [TRANSACTION_STATUS.ERROR]: [
          {
            'text': TRANSLATION_KEY_MAP.TRY_AGAIN,
            'color': 'transaction-primary',
            'action': this.handleStartPayment
          }
        ]
      },
      statusToDataMap: {}
    };
  },

  /**
   * Computed properties for the component.
   *
   * @returns {Object} - The computed properties for the component.
   */
  computed: {
    ...mapGetters({
      'paymentStatus': 'payment/getPaymentStatus'
    })
  },
  async beforeMount() {
    await this.setupTemplate();
    this.setupStatusToDataMap();
    this.setupLandingPage();
  },
  methods: {
    ...mapActions('payment', ['getStatus', 'startPayment', 'getRedirect', 'getTemplate']),
    ...mapMutations('payment', ['SET_PAYMENT_STATUS']),

    /**
     * Setup the template for the landing page.
     *
     * This function retrieves the template data from the server using the correlation ID
     * provided in the props. If the correlation ID is falsy, the user is redirected to
     * the error page. If the template data retrieval is successful, the logo URL is set
     * to the logo URL retrieved from the server.
     *
     * @return {Promise<void>} Promise that resolves when the function completes.
     */
    async setupTemplate() {
      // If there is no correlation ID, redirect the user to the error page
      if (!this.correlationId) {
        this.$router.push('/error');
      }

      try {
        // Retrieve the template data using the correlation ID
        let getTemplateResponse = await this.getTemplate(this.correlationId);

        // Set the logo URL to the logo URL retrieved from the server
        this.logoUrl = getTemplateResponse.logo;
      } catch (error) {
        // Log any errors that occur during template data retrieval
        console.log(error);
      }
    },
    /**
     * Generates a map of transaction statuses to their corresponding data, including actions.
     *
     * @return {void} This function does not return a value.
     */
    setupStatusToDataMap() {
      let statusMap = {};
      Object.entries(TRANSACTION_STATUS_DATA).forEach(([key, value]) => {
        statusMap[key] = {
          ...value,
          'actions': this.statusActions[key] || []
        };
      });
      this.statusToDataMap = statusMap;
    },
    /**
     * Asynchronously sets up the landing page by handling payment status, checking for correlation ID, getting status, and handling API responses.
     * If the correlation ID is not present, it redirects to the error page. If an error occurs during the process, it also redirects to the error page.
     * 
     * @return {void} 
     */
    async setupLandingPage(){
      // console.log('---------------- seting up landing page ----------------');
      this.SET_PAYMENT_STATUS(TRANSACTION_STATUS.LOADING);
      if (!this.correlationId){
        this.$router.push('/error');
      }
      try {
        let response = await this.getStatus(this.correlationId);
        let redirecting = await this.handleAPIResponse(response);
        if (!redirecting){
          if (response === TRANSACTION_STATUS.NOT_STARTED) {
            this.handleStartPayment();
          } else if (response === TRANSACTION_STATUS.STARTED) {
            this.handleRedirect();
          } else if (response === TRANSACTION_STATUS.SENT && this.$route?.query?.action !== 'WaitForNotification') {
            /**
             * Handles the start of the payment process when the transaction status is 'SENT'.
             *
             * This function is called when the transaction status is 'SENT'. It calls the
             * 'handleStartPayment' method to handle the start of the payment process.
             *
             * @return {void} This function does not return anything.
            */
            this.handleStartPayment();
          } else if (response === TRANSACTION_STATUS.SENT && this.$route?.query?.action === 'WaitForNotification') {
            // Set the payment status to 'LOADING' while waiting for the transaction status to change from 'SENT'
            this.SET_PAYMENT_STATUS(TRANSACTION_STATUS.LOADING);

            // Record the start time of the loop
            const startTime = Date.now();

            // Run the loop until the time elapsed is greater than 1 minute or the transaction status is no longer 'SENT'
            while ((Date.now() - startTime) < 60000 && response === TRANSACTION_STATUS.SENT) {
              
              // Get the current transaction status
              response = await this.getStatus(this.correlationId);

              // If the transaction status is no longer 'SENT', exit the loop
              if (response !== TRANSACTION_STATUS.SENT) {
                break;
              }

              // Wait for 1 second before checking the transaction status again
              await new Promise(resolve => setTimeout(resolve, 1000));
            }

            // If the transaction status is still 'SENT' after the loop, set the payment status to 'ERROR' and return true to indicate that the function has redirected
            if (response === TRANSACTION_STATUS.SENT) {
              this.SET_PAYMENT_STATUS(TRANSACTION_STATUS.ERROR);
              return true;
            }
          }
        }
      } catch {
        this.SET_PAYMENT_STATUS(TRANSACTION_STATUS.ERROR);
      }
    },
    /**
     * Handles the start of the payment process by calling the startPayment method with the provided correlationId.
     *
     * @param {string} correlationId - The unique identifier for the payment transaction.
     * @return {void} This function does not return anything.
     */
    async handleStartPayment() {
      let startPaymentResponse = await this.startPayment(this.correlationId);
      const redirecting = this.handleAPIResponse(startPaymentResponse);
      if (!redirecting) {
        this.handleRedirect();
      }
    },
    async handleRedirect() {
      this.getRedirect(this.correlationId).then((redirectUri) => {
        window.location.href = redirectUri;
      });
    },
    /**
     * Handle API response based on status code.
     *
     * @param {Object} response - The response object from the API
     * @return {boolean} Indicates if the response was successfully handled or not
     */
    handleAPIResponse(response){
      // console.log('------------ handle API response ------------');
      switch (response.status){
        case 404:
          // console.log('------------ error landing page ------------');
          this.$router.push('/error');
          return true;
        case 409:
          // console.log('------------ setup landing page ------------');
          this.setupLandingPage();
          return true;
        case 500:
          // console.log('------------ error landing page ------------');
          this.SET_PAYMENT_STATUS(TRANSACTION_STATUS.ERROR);
          return true;
        default:
          return false;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.landing-page-custom-border {
  border-top: 18px solid #6EF3DC !important;
}

.lang-switch-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.btn-transaction-primary {
  background: rgb(110, 243, 220);
  color: rgb(18, 38, 63);
  border-radius: 70px;
  padding: 0px 36px;
  font-weight: 600;
  border: none;
}

.status-title {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 40px;
  line-height: 125%;
  color: #12263F;
  margin-top: 50px;
}

.status-message {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 152%;
  color: #6E84A3;
  margin-top: 24px;
}

.loading-state__text {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.actions-container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.loading-container{
  position: relative;
  height: 200px;
}
</style>
<style lang="scss">
.landing-page-loader {
  .spinner-border {
    border-top-color: #47D3BA;
    border-left-color: #47D3BA;
    border-bottom-color: #47D3BA;
  }
}
</style>