import { render, staticRenderFns } from "./LocaleSwitcher.vue?vue&type=template&id=264fc207"
import script from "./LocaleSwitcher.vue?vue&type=script&lang=js"
export * from "./LocaleSwitcher.vue?vue&type=script&lang=js"
import style0 from "./LocaleSwitcher.vue?vue&type=style&index=0&id=264fc207&prod&lang=scss"


/* normalize component */
import normalizer from "!C:\\npm\\prefix\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports