<template>
  <img class="logo" :src="fullLogoUrl" alt="Logo" />
</template>

<script>
export default {
  props: {
    logoUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    fullLogoUrl() {
      return `${window.location.origin}${this.logoUrl}`;
    }
  }
};
</script>

<style scoped>
.logo {
  max-height: 140px;
}
</style>
