import Vue from 'vue';
import {
  Alert,
  CAlert,
  CAlertGroup,
  BtnGroup,
  DataGrid,
  DataGridFilter,
  Button,
  CInput,
  BaseIcon,
  NavTabs,
  NavTabsContent,
  CToggleSwitch,
  PageHeaderMain,
  TheNav,
  TheTopnav,
  TheNavSmall,
  Spinner,
  Loader,
  CLabel
} from '@cendyn/cendyn-frontend-kit';

let Components = () => {
  Vue.component('Alert', Alert);
  Vue.component('CAlert', CAlert);
  Vue.component('CAlertGroup', CAlertGroup);
  Vue.component('BtnGroup', BtnGroup);
  Vue.component('DataGrid', DataGrid);
  Vue.component('DataGridFilter', DataGridFilter);
  Vue.component('Button', Button);
  Vue.component('CInput', CInput);
  Vue.component('BaseIcon', BaseIcon);
  Vue.component('NavTabs', NavTabs);
  Vue.component('NavTabsContent', NavTabsContent);
  Vue.component('CToggleSwitch', CToggleSwitch);
  Vue.component('PageHeaderMain', PageHeaderMain);
  Vue.component('TheNav', TheNav);
  Vue.component('TheTopnav', TheTopnav);
  Vue.component('TheNavSmall', TheNavSmall);
  Vue.component('Spinner', Spinner);
  Vue.component('Loader', Loader);
  Vue.component('CLabel', CLabel);
};

export default Components;
