import { render, staticRenderFns } from "./LandingPage.vue?vue&type=template&id=6ce171a6&scoped=true"
import script from "./LandingPage.vue?vue&type=script&lang=js"
export * from "./LandingPage.vue?vue&type=script&lang=js"
import style0 from "./LandingPage.vue?vue&type=style&index=0&id=6ce171a6&prod&scoped=true&lang=scss"
import style1 from "./LandingPage.vue?vue&type=style&index=1&id=6ce171a6&prod&lang=scss"


/* normalize component */
import normalizer from "!C:\\npm\\prefix\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce171a6",
  null
  
)

export default component.exports