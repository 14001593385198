<template>
  <LocaleSwitcher />
</template>

<script>
import LocaleSwitcher from '../components/LocaleSwitcher';

export default {
  components: { LocaleSwitcher }
};
</script>