import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=48764850"
import script from "./Nav.vue?vue&type=script&lang=js"
export * from "./Nav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!C:\\npm\\prefix\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports