<template>
  <div class="locale-switcher">
    <dropdown input-id="payment-locale-switcher" class="payment-locale-switcher">
      <template #dropdown-trigger>
        <div class="selected-locale-container">
          <BaseIcon icon-name="globe" :size="16" />
          <span class="selected-locale">{{ selectedLocaleName }}</span>
          <base-icon icon-name="chevron-down" :size="20" />
        </div>
      </template>
      <template #dropdown-body>
        <div v-for="locale in locales" :key="locale.code" class="locale-option" :class="{'active': locale.code === $i18n?.locale}" @click="changeLocale(locale)">{{ locale.name }}</div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import { getSupportedLocales } from '@/util/i18n/supported-locales';
import {Dropdown, BaseIcon} from '@cendyn/cendyn-frontend-kit';
import { getCookie, updateLocaleCookie } from '@/language';
import i18n from '../language';
export default {
  components:{Dropdown, BaseIcon},
  data() {
    return {
      locales: getSupportedLocales(),
      pageTitle: 'PageTitle',
      selectedLocaleName: undefined
    };
  },
  created() {
    this.updatePageTitle();
  },
  mounted(){
    const locale = this.locales.find(locale => locale.code === (getCookie('selected-locale') || this.$i18n.locale));
    this.changeLocale(locale);
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale.code;
      this.selectedLocaleName = locale.name;
      updateLocaleCookie(locale.code);
      this.updatePageTitle();
    },
    updatePageTitle() {
      this.pageTitle = this.$t('PageTitle');
      document.title = this.pageTitle;
    }
  }
};
</script>
<style lang="scss">
.payment-locale-switcher{
  #payment-locale-switcher{
    &:hover{
      background-color: transparent;
    }
  }
  .selected-locale-container {
    display: flex;
    align-items: center;

    .selected-locale{
      margin-left:8px;
      margin-right: 8px;
      color: #12263F !important;
      font-size: 15px;
      font-weight: 600;

      &:hover{
        background-color: transparent !important;
      }
    }
  }
  .dropdown-menu{
    overflow: hidden;
    left: -50px !important;
    padding: 8px 0;
    box-shadow: 0px 2px 25px -8px rgba(18, 38, 63, 0.15),0px 1px 3px 0px rgba(18, 38, 63, 0.1);
  }
}
.locale-option{
  padding: 8px 24px;
  cursor: pointer;
  color: #6E84A3;
  font-weight: 600;
  font-size: 13px;

  &:hover, &.active{
    background-color: #EDF2F9;
  }
}
</style>