<template>
  <div>
    <Loader v-if="!configuration"/>
    <add-integration
      v-else
      is-editing
      :editing-configuration="configuration"
      :editing-configuration-id="configurationId"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import AddIntegration from './AddIntegration.vue';
// import { Loader } from '@cendyn/cendyn-frontend-kit';
export default {
  name: 'EditIntegration',
  components: {
    AddIntegration
  },
  props:{
    configurationId:{
      type:[String, Number],
      default: null
    }
  },
  data(){
    return {
      configuration: null
    };
  },
  beforeMount(){
    this.setupEditConfiguration();
  },
  methods:{
    ...mapActions('configuration', [
      'fetchConfiguration',
      'fetchConfigurationLogo'
    ]),
    async setupEditConfiguration(){
      const [configuration, configLogo] = await Promise.all([
        this.fetchConfiguration(this.configurationId),
        this.fetchConfigurationLogo(this.configurationId)
      ]);
      if (configuration){
        this.configuration = {...configuration, configLogo};
      } else {
        this.$router.push('/integrations');
      }
    }
  }
};
</script>