import { render, staticRenderFns } from "./Integrations.vue?vue&type=template&id=145f0f96"
import script from "./Integrations.vue?vue&type=script&lang=js"
export * from "./Integrations.vue?vue&type=script&lang=js"
import style0 from "./Integrations.vue?vue&type=style&index=0&id=145f0f96&prod&lang=scss"


/* normalize component */
import normalizer from "!C:\\npm\\prefix\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports