// Slovenian
export default {
  PageTitle: 'Plačilo rezervacije',
  //loading,Started
  loading_title: 'Preverjanje, prosim počakajte...',
  loading_message: 'Ne zapirajte tega okna',
  //invalid-transaction
  NoTransaction_title: 'Napaka',
  NoTransaction_message: 'Ni mogoče najti podrobnosti vaše rezervacije za dokončanje tega plačila. Obrnite se neposredno na hotel.',
  //Pending,Authorized,Finalized
  Authorized_title: 'Vaša transakcija je dokončana',
  Authorized_message: 'Vaše plačilo je bilo uspešno zaključeno. Preverite vaš nabiralnik za potrditveno e-pošto.',
  //Failed, Expired, Cancelled
  Error_title: 'Napaka pri transakciji',
  Error_message: 'Prišlo je do napake pri vaši plačilni transakciji. Prosim, poskusite znova ali stopite v stik s hotelom neposredno.',
  //Sent
  Sent_title: 'Hvala!',
  Sent_message: 'Vaša transakcija je v teku. Obvestili vas bomo po e-mail, ko bo plačilo dokončano.',
  //actions-buttons
  Action_TryAgain: 'Poskusi ponovno'
};
