import { GetDataGridFilterPageSettings } from '@cendyn/cendyn-frontend-kit/src/plugins/DataGridFilter/types/settings/page-size-button-settings';
let DEFAULT_DATAGRID_FILTER_PAGE_SETTINGS = GetDataGridFilterPageSettings();
DEFAULT_DATAGRID_FILTER_PAGE_SETTINGS.enabled = true;

//Overriding the DEFAULT_DATAGRID_FILTER_PAGE_SETTINGS availableSizes
DEFAULT_DATAGRID_FILTER_PAGE_SETTINGS.availableSizes = ['12', '24', '48', '60'];


export const gridSettings = {
  allowSorting: true,
  allowPaging: true,
  allowFiltering: true,
  allowMultiSorting: false,
  pageSettings: {
    pageSize: DEFAULT_DATAGRID_FILTER_PAGE_SETTINGS.selectedPageSize
  },
  pageButtonSettings: DEFAULT_DATAGRID_FILTER_PAGE_SETTINGS
};
