import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=00238410&scoped=true"
import script from "./ErrorPage.vue?vue&type=script&lang=js"
export * from "./ErrorPage.vue?vue&type=script&lang=js"
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=00238410&prod&scoped=true&lang=scss"
import style1 from "./ErrorPage.vue?vue&type=style&index=1&id=00238410&prod&lang=scss"


/* normalize component */
import normalizer from "!C:\\npm\\prefix\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00238410",
  null
  
)

export default component.exports