// en.js
export default {
  PageTitle: 'Reservation Payment',
  //loading,Started
  loading_title: 'Checking, please wait...',
  loading_message: 'Do not close this window',
  //invalid-transaction
  NoTransaction_title: 'Error',
  NoTransaction_message: 'Unable to locate your reservation details to complete this payment.  Please contact the hotel directly.',
  //Pending,Authorized,Finalized
  Authorized_title: 'Your transaction is complete',
  Authorized_message: 'Your payment was completed successfully.  Check your inbox for a confirmation email.',
  //Failed, Expired, Cancelled
  Error_title: 'Transaction error',
  Error_message: 'There is an error with your payment transaction.  Please try again or contact the hotel directly.',
  //Sent
  Sent_title: 'Thank you!',
  Sent_message: 'Your transaction is in progress.  We will send an email when the payment is completed.',
  //actions-buttons
  Action_TryAgain: 'Try Again'
};
