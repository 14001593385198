import Vue from 'vue';

export default {
  get: async (url, errorValue = null) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$authService.tokenManager.get('accessToken').then(async function (token) {
        fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.accessToken}`
          }
        }).then(async response => {
          if (response.status === 200 ){
            const contentType = response.headers.get('content-type');
            if (contentType?.indexOf('application/json') !== -1){
              resolve(await response.json());
            } else {
              resolve(await response.text());
            }
          } else {
            reject(errorValue);
          }
        }).catch(() => {
          reject(errorValue);
        });
      });
    });
  },
  post: async (url, body) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$authService.tokenManager.get('accessToken').then(async function (token) {
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.accessToken}`
          },
          body: JSON.stringify(body)
        }).then(async response => {
          resolve(await response.json());
        }).catch(error => {
          reject(error);
        });
      });
    });
  },
  put: async (url, body, isMultiPartFormData = false) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$authService.tokenManager.get('accessToken').then(async function (token) {
        const headers = {
          'Authorization': `Bearer ${token.accessToken}`
        };
        if (!isMultiPartFormData) {
          headers['Content-Type'] = 'application/json';
          body = JSON.stringify(body);
        }
        fetch(url, {
          method: 'PUT',
          headers: headers,
          body
        }).then(async response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    });
  }
};