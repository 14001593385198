<template>
     <div class="row">
        <page-header-main
          id="integration-list"
          title="Integrations"
          class="col-12"
          :breadcrumbs="[{name: 'PAYMENT PORTAL'},{name : 'INTEGRATIONS'}]"
          :buttons="[{id: '1',text: 'Add Integration'}]"
          @clicked="handleAddIntegration"
        />
      <div class="col-12">
        <data-grid-filter :id="`${gridSettings.id}-filter`"
          ref="dataGridFilterRef"
          :grid-id="gridSettings.id"
          :page-size-button-settings="gridSettings.pageButtonSettings"
          :view-settings="viewSettings"
          @change="onChanged($event)"
          @filterChange="$onFilterChanged($event, $onFilterChanged)"
          @orderChange="$onSortChanged($event, $onSortChanged)"
        >
          <template #gridview>
            <data-grid v-show="integrations"
              :id="gridSettings.id"
              ref="dataGrid"
              :data-source="integrations"
              :data-state-change="dataStateChange"
              :allow-paging="true"
              :allow-sorting="true"
              :allow-filtering="true"
              :allow-selection="false"
              :columns="gridSettings.columns"
              class="grid-remove-padding no-border"
              @edit-integration="handleEditIntegration"
            />
          </template>
        </data-grid-filter>
      </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { DataFilterEventHandlerMixin, PageHeaderMixin } from '@mixins';
import { DEFAULT_GRID_SETTINGS } from '@constants';
import { mapActions, mapMutations } from 'vuex';
import { DataGridColumnActionTemplate } from '@cendyn/cendyn-frontend-kit';
import Vue from 'vue';

const IntegrationStatusTemplate = Vue.component('integration-status-template', {
  computed:{
    fillColor() {
      return this.data.status ? '#008A50' : '#6E84A3';
    }
  },
  template: `
    <div class="d-flex align-items-center">
        <div :style="'height:8px;width:8px;background-color: '+ fillColor +';border-radius:50%;'"/>&nbsp;
        {{ data.status ? 'Active': 'Inactive' }}
    </div>
  `
});

export default {
  mixins: [PageHeaderMixin, DataFilterEventHandlerMixin],
  data() {
    return {
      getMainTitle: 'Integration',
      breadcrumbs : [
        {
          'name': 'PAYMENT PORTAL',
          'link': '/admin/integrations'
        },
        {
          'name': 'INTEGRATIONS',
          'link': '/'
        }
      ],
      paginationKey: 0,
      cardCurrentPage: 1,
      cardCurrentPageSize: 12,
      viewSettings: {
        enabled: false,
        views: [
          {
            id: 'gridview',
            type: 'grid',
            icon: 'list',
            active: true
          }
        ]
      },
      integrationGridSettings: {
        id: 'integrations',
        columns: [
          {
            field: 'product',

            headerText: 'Product'
          },
          {
            field: 'name',
            headerText: 'Organization'
          },
          {
            field: 'paymentProvider',
            headerText: 'Payment Provider'
          },
          {
            field: 'updatedOn',
            headerText: 'Updated On'
          },
          {
            field: 'updatedBy',
            headerText: 'Updated By'
          },
          {
            field: 'status',
            headerText: 'Status',
            subType: 'dropdown',  // define sub type = dropdown to display column filter as dropdown
            dataSource: [{ description: 'Active', value: 'true' }, { description: 'Inactive', value: 'false' }], // add key value pair for dropdown options
            dataFields: {
              text: 'description',
              value: 'value'
            },
            template: function () {
              return {
                template: {
                  extends: IntegrationStatusTemplate
                }
              };
            }
          },
          {
            field: '',
            type:'actions',
            headerText: '',
            template: function () {
              return {
                template: {
                  extends: DataGridColumnActionTemplate,
                  data() {
                    return {
                      actions: [
                        { eventName: 'edit-integration', text: 'Edit' }
                      ]
                    };
                  }
                }
              };
            }
          }
        ]
      },
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('integration', [
      'getIntegrationIntialized',
      'getIntegrationList',
      'getIntegrationCount',
      'getIntegrationFilterBody',
      'getIntegrationSearchParameter'
    ]),
    apiGridSettings() {
      let result = [];
      let searchParams = this.getIntegrationSearchParameter;
      if (searchParams == null) {
        return null;
      }
      searchParams.fields.forEach(field => {
        let sanitizedFieldName = field.field.charAt(0).toLowerCase() + field.field.slice(1); // Converting the first character to lower case and concatenating the rest of the string. 
        let column = this.integrationGridSettings.columns.find(col => col.field === sanitizedFieldName);
        if (column) {
          result.push({
            field: field.field,
            type: column.type ? column.type : (field.type.toLowerCase() === 'datetime' || field.type.toLowerCase() === 'datetimeoffset') ? 'date' : field.type.toLowerCase(),
            allowedOperators: column.allowedOperators ? column.allowedOperators : field.allowedOperators.map(op => op.operator),
            allowFiltering: column.allowFiltering ? column.allowFiltering : field.allowedOperators.length > 0,
            allowSorting: column.allowSorting ? column.allowSorting : searchParams.orderColumns.includes(field.field)
          });
        }
      });
      return {
        id: 'integrations',
        columns: result
      };
    },
    formatList() {
      //Formatting API response to allow Card and Grid view to display it properly
      let formattedData = [];
      if (this.getIntegrationIntialized) {
        this.getIntegrationList.map(item => {
          const formattedTime = new Date(item.modifiedOn).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
          let formatted = {
            'product': item.productName,
            'name': item.name + ' (' + item.hotelId + ')',
            'paymentProvider': item.paymentProcessor,
            'updatedOn': formattedTime,
            'updatedBy': item.modifiedBy,
            'status': item.activeYN,
            'clientConfigurationId': item.clientConfigurationId
          };
          formattedData.push(formatted);
        });
      }
      return formattedData;
    },
    integrations() {
      let defaultPagination = {
        pageNumber: 1,
        pageSize: DEFAULT_GRID_SETTINGS.pageSettings.pageSize
      };
      if ('pagination' in this.getIntegrationFilterBody) {
        defaultPagination = this.getIntegrationFilterBody.pagination;
      }
      let data = {
        count: this.getIntegrationIntialized ? this.getIntegrationCount : 0,
        //Initial pagination values, this will only be used at startup after it will be handled by $onPageChanged
        pagination: defaultPagination,
        result: this.formatList
      };
      return data;
    },
    gridSettings() {
      let shallowMerge = Object.assign(DEFAULT_GRID_SETTINGS, this.integrationGridSettings);
      return _.merge(this.apiGridSettings, shallowMerge);
    }
  },
  created() {
    this.fetchIntegrationParameters();
  },
  mounted() {
    if ('where' in this.getIntegrationFilterBody) {
      let refreshGridFilterBody = {
        'where': [],
        'order': [],
        'pagination': {
          'pageNumber': 1,
          'pageSize': 12
        }
      };
      this.SET_INTEGRATION_FILTER(refreshGridFilterBody);
      this.fetchIntegrationFiltered();
    }
  },
  methods: {
    ...mapActions('integration', [
      'fetchIntegrationFiltered',
      'fetchIntegrationParameters'
    ]),
    ...mapMutations('integration', [
      'SET_INTEGRATION_FILTER'
    ]),
    handleAddIntegration(){
      this.$router.push({ name: 'AddIntegration' });
    },
    handleEditIntegration(data){
      this.$router.push({ name: 'EditIntegration', params: { configurationId: data.clientConfigurationId, isEditing: true } });
    },
    /* eslint-disable-next-line no-unused-vars */
    async dataStateChange(args) {
      if (args.action.requestType === 'paging') {
        this.$onPageChanged(args);
        //Calling separate function to update card paging
        this.cardPaginationSetter(args);
      }
    },
    goToLogout() {
      this.$router.push({ name: 'Logout' });
    },
    goToIntegration() {
      
    },
    cardPaginationSetter(args) {
      this.cardCurrentPage = args.action.currentPage;
      this.cardCurrentPageSize = args.action.pageSize;
      this.paginationKey++;
    },
    cardPaginationHandler(args) {
      this.$onCardPageChanged(args);

      //Calling grid.goToPage, so List component rerenders to correct page
      let grid = this.$refs.dataGrid.$refs.ejsdatagrid;
      grid.goToPage(args.currentPage);
    },
    onChanged(filter) {
      this.$emit('change', filter);
    }
  }
};
</script>

<style lang="scss">
    .card-audience__dropdown-menu {
        display: none;
    }

    .audience-footer-spacing {
        margin-top: 25px !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .cpopover-actions__wrap a.cpopover-actions__item {
      font-size: 14px;
      &:hover {
          cursor: pointer;
          background: transparent;
          color: #12263F;
      }
    }
</style>