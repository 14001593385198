// src/store.js
import Vue from 'vue';
import Vuex from 'vuex';
import { paymentStore } from './modules/payment';
import { configurationStore } from './modules/configuration';
import { integrationStore } from './modules/integration';
const store = {
  state: {
    version: '1.0.0'
  },
  modules: {
    payment: paymentStore,
    configuration: configurationStore,
    integration: integrationStore
  }
};
Vue.use(Vuex);
export default new Vuex.Store(store);

