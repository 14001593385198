import Vue from 'vue';

// src/store.js
export const integrationStore = {
  namespaced: true,
  state: () => ({
    searchParameters: {},
    list: null,
    filterBody: {
      'where': [],
      'order': [],
      'pagination': {
        'pageNumber': 1,
        'pageSize': 12
      }
    }

  }),
  mutations: {
    SET_SEARCH_PARAMETERS(state, searchParams) {
      state.searchParameters = searchParams;
    },
    SET_INTEGRATION_LIST(state, list) {
      state.list = list;
    },
    SET_INTEGRATION_FILTER(state, filter) {
      state.filterBody = filter;
    }
  },
  actions: {
    async fetchIntegrationParameters({ commit }) {
      await Vue.prototype.$authService.tokenManager.get('accessToken').then(async function (token) {
        await fetch('../api/admin/configurations-search-parameters', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.accessToken}`
          }
        }).then(async response => {
          const responseBody = await response.json();
          commit('integration/SET_SEARCH_PARAMETERS', responseBody, { root: true });
        }).catch(error => {
          console.log(error);
        });
      });
    },
    async fetchIntegrationFiltered({ commit, rootState }) {
      await Vue.prototype.$authService.tokenManager.get('accessToken').then(async function (token) {
        return await fetch('../api/admin/configurations-search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.accessToken}`
          },
          body: JSON.stringify(rootState.integration.filterBody)
        }).then(async response => {
          const responseBody = await response.json();
          commit('integration/SET_INTEGRATION_LIST', responseBody, { root: true });
        }).catch(error => {
          console.log(error);
        });
      }).catch(error => {
        console.log(error);
      });
      
    }
  },
  getters: {
    getIntegrationIntialized: state => state.list != null,
    getIntegrationList: state => state.list.items,
    getIntegrationCount: state => state.list.totalRecords,
    getIntegrationFilterBody: state => state.filterBody,
    getIntegrationSearchParameter: state => state.searchParameters.data
  }
};
