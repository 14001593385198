import { DEFAULT_GRID_SETTINGS } from '@constants';
import { mapMutations, mapActions } from 'vuex';
export default {
  data() {
    return {
      baseFilter: {
        where: [],
        order: [],
        pagination: {
          pageNumber: 1,
          pageSize: DEFAULT_GRID_SETTINGS.pageSettings.pageSize
        }
      },
      currentFilter: {}
    };
  },
  created() {
    this.currentFilter = JSON.parse(JSON.stringify(this.baseFilter));
  },
  methods: {
    ...mapMutations('integration', [
      'SET_INTEGRATION_FILTER'
    ]),
    ...mapActions('integration', [
      'fetchIntegrationFiltered'
    ]),
    $onClearFilter(dateFilterRef) {
      this.currentFilter = JSON.parse(JSON.stringify(this.baseFilter));
      dateFilterRef.onClearFilterBtnClicked();
    },
    $onFilterChanged(where, callback) {

      this.currentFilter.where = JSON.parse(JSON.stringify(this.baseFilter.where));
      if (where?.length > 0) {
        //Setting the baseFilter where to the newly selected filter connector & condition, then calling SET_AUDIENCE_FILTER & updating grid items with new filtered results
        this.baseFilter.where = where;
        this.baseFilter.pagination = {
          pageNumber: 1,
          pageSize: 12
        };
        this.SET_INTEGRATION_FILTER(this.baseFilter);
        this.fetchIntegrationFiltered();
        this.currentFilter.where.push(where);
      }

      if (where?.length === 0) {
        //If the where.lenght array is empty, clear filter has been clicked and clearing both the local this.baseFilter.where and setting the audience filter body as {}
        this.baseFilter.where = [];
        this.baseFilter.pagination = {
          pageNumber: 1,
          pageSize: 12
        };
        let refreshGridFilterBody = {
          'where': [],
          'order': [],
          'pagination': {
            'pageNumber': 1,
            'pageSize': 12
          }
        };
        this.SET_INTEGRATION_FILTER(refreshGridFilterBody);
        this.fetchIntegrationFiltered();
      }
      this.currentFilter.pagination.pageNumber = 1;

      if (callback) {
        callback.call(this, this.currentFilter);
      }
    },
    $onSortChanged(order, callback) {
      //If the order length is greater than 0, sorting will be applied
      //If the order length is 0, meaning sorting array is empty. Grid sorting will be cleared
      if (order?.length >= 0) {
        this.baseFilter.order = order;
        this.SET_INTEGRATION_FILTER(this.baseFilter);
        this.fetchIntegrationFiltered();
      }

      if (callback) {
        callback.call(this, this.currentFilter);
      }
    },
    $onPageChanged(pagingEvent, callback) {
      if (pagingEvent?.action.requestType !== 'paging') {
        return;
      }

      //Setting the baseFilters pagination values to new pageEvent values, then calling fetchAudiencesFiltererd API to update view
      this.baseFilter.pagination.pageNumber = Number(pagingEvent.action.currentPage);
      this.baseFilter.pagination.pageSize = Number(pagingEvent.take);
      this.SET_INTEGRATION_FILTER(this.baseFilter);
      this.fetchIntegrationFiltered();
      

      if (callback) {
        callback.call(this, this.currentFilter);
      }
    },
    $onCardPageChanged(pagingEvent) {

      //Using a separate call for the card pagination
      //Setting the baseFilters pagination values to new pageEvent values, then calling fetchAudiencesFiltererd API to update view
      this.baseFilter.pagination.pageNumber = Number(pagingEvent.currentPage);
      this.baseFilter.pagination.pageSize = Number(pagingEvent.pageSize);
      this.SET_INTEGRATION_FILTER(this.baseFilter);
      this.fetchIntegrationFiltered();
      
    },
    $updatePageNumber(newPageNumber, oldPageNumber, gridRef = null) {
      if (newPageNumber !== oldPageNumber) {
        const grid = gridRef ?? this.$refs.dataGrid?.$el.ej2_instances[0] ?? this.$el?.closest('.e-grid').ej2_instances[0];
        if (grid) {
          try {
            grid.goToPage(newPageNumber);
          } catch (error) {
            console.warn('DataFilterEventHandlerMixin', error);
          }
        }

      }
    }
  }
};
