// src/auth.js
import { OktaAuth } from '@okta/okta-auth-js';
import store from '@/store';

// Create a function to initialize OktaAuth
const oktaAuthPromise = store.dispatch('configuration/loadConfiguration').then(async (config) => {
  // Create an OktaAuth instance with the loaded configuration
  return new OktaAuth({
    clientId: config.clientId,
    issuer: config.issuer,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email'],
    tokenManager: {
      storage: 'localStorage'
    }
  });
});

export default oktaAuthPromise;
