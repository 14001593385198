import { render, staticRenderFns } from "./ServiceProviderTemplate.vue?vue&type=template&id=246ee142"
import script from "./ServiceProviderTemplate.vue?vue&type=script&lang=js"
export * from "./ServiceProviderTemplate.vue?vue&type=script&lang=js"
import style0 from "./ServiceProviderTemplate.vue?vue&type=style&index=0&id=246ee142&prod&lang=scss"


/* normalize component */
import normalizer from "!C:\\npm\\prefix\\node_modules\\@vue\\cli-service\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports